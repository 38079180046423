// Change this for page headings
.app {
	display: flex;
	justify-content: flex-end;

	&__outer {
		width: 100%;
		height: 100vh;
		background-color: #f4f7fc;
		display: flex;
		position: relative;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		// margin-top: 3.5rem;
		overflow-x: hidden;
		// Firefox Scrollbar
		scrollbar-width: thin;
	}
	&__embeded {
		height: 100vh !important;
		margin-top: 0rem !important;
	}
}
.highcharts-button-box {
	fill: transparent;
}

.MuiAccordion-root{
	box-shadow: none !important;
}
.commonPageHeading {
	color: var(--pageHeading);
	padding: 0.5rem 1rem;
	font-size: 24px;
	font-weight: 600;
	height: 74px;
	background-color: white;
	justify-content: space-between;
	align-items: center;
	display: flex;
	// box-shadow: rgb(100 100 111 / 20%) 0px 7px 10px 0px;
}

.pageheadingFixed {
	position: fixed;
	display: flex;
	align-items: center;
	// width: calc(98% - var(--sidebar-width));
	width: -webkit-fill-available;
	width: -moz-available;
	z-index: 2;
}

.marginForFixedHeading {
	margin-top: 3rem;
	overflow-y: auto;
	width: 100%;
	height: calc(100% - 3rem);
}

.commonSubHeading {
	// color: var(--subHeading);
	padding: 1rem;
	font-size: 20px;
	float: left;
	font-weight: 600;
}

.notDataFoundList {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	font-weight: 500;
	letter-spacing: 0.5px;
	color: darkslategrey;
	overflow: hidden;
}

.notDataFoundList .MuiSvgIcon-root {
	color: slategray;
}

@media screen and (max-width: 1366px) {
	.commonPageHeading {
		height: 88px;
	}
	.marginForFixedHeading {
		margin-top: 5rem;
		// commonpage heading - padding - header
		height: calc(100% - 6rem);
	}
}

.highcharts-scrollbar:horizontal{
	width:5px
}
.highcharts-scrollbar-track {
  display:none;
}
.highcharts-scrollbar-button {
  display: none;
}
.highcharts-scrollbar-thumb{
    fill: #9149ff !important;
	height: 0.6em;
	rx: 5px;
	ry:9px
}
.highcharts-scrollbar-rifles{
	display: none;
}


// .highcharts-scrollbar-thumb {
// 	background-color: #9149ff !important;
// }
::-webkit-scrollbar {
	background-color: transparent !important;
	width: 0.5em !important;
	height: 4px !important;
}

::-webkit-scrollbar:horizontal{
	width: 5px;
}

/* Handle */

::-webkit-scrollbar-thumb {
	// background-color: #9149ff !important;
	border-radius: 10px;
}

.MuiPaper-root {
	border-radius: 15px !important;
}

.headerBorderRadius {
	border-radius: 0px !important;
}

@media screen and (max-width: 1366px) {
	.headerBorderRadius {
		height: 88px !important;
	}
}

// .MuiDialog-paper{
// 	background-color: #1A1A1A !important;
// }
