@mixin tabStyle($property, $value, $property1, $value1) {
	cursor: pointer;
	padding: 0.5rem 1rem;
	margin-right: 0.7rem;
	margin-top: 1.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 12px;
	border-radius: 50px;
	background-color: rgb(255, 255, 255);

	@if $property {
		@if $value {
			#{$property}: $value;
		}
	}

	@if $property1 {
		@if $value1 {
			#{$property1}: $value1;
		}
	}

	// :hover {
	// 	background-color: var(--primary-theme) !important;
	// 				color: white;
	// 				transition: 0.3s ease-in-out;
	// }
}

$border-radius: 20px;
$global_box_shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
	rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
// $success_1: #2ecc71;
// $success_2: #96b150;
// $warning: #e67e22;
// $danger: #e74c3c ;
// #6a539d
// #7e69ad
// #927fbd
// #a796cd
// $success_1: #6a539d;
// $success_2: #7e69ad;
// $warning: #927fbd;
// $danger: #a796cd;
$success_1: #9772ea;
$success_2: #f009ff;
$warning: #ff00ba;
$danger: #ff282d;

@mixin drawLine {
	border: 2px solid red;
}

@mixin center_via_flex {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin lead_estimate_Default_parent_style {
	max-height: auto;
	margin: auto;
	border-radius: $border-radius;
	padding: 1rem;
}

@mixin icon_container_style($right, $top) {
	cursor: pointer;
	position: absolute;
	right: $right;
	top: $top;
}

.Advertisement_channel_effect {
	height: 100%;
	overflow: auto;
	scroll-behavior: smooth !important;

	.MuiAccordionSummary-root {
		min-height: 75px;
	}
	.MuiAccordion-root {
		position: relative;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0.5; // Example opacity
			z-index: -1; // Ensure it's behind the Accordion content
		}
	}

	.dataProfile {
		transition: transform 0.3s ease;
	}

	.dataProfile:hover {
		transform: scale(1.05); /* Scale up to 105% on hover */
	}
	.advertisementContainer {
		margin-top: 4rem;
		height: calc(100vh - 4rem);
		overflow-y: auto;
		width: 100%;
	}

	.pageheadingFixed {
		z-index: 4;
	}

	.page-heading {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.ppc__scrollArea {
		// height: 100%;
		// width: 100%;
		// overflow: auto;
		// scroll-behavior: smooth !important;
		.graph_and_table_container {
			min-height: 350px;
			background-color: white;
			padding-top: 2rem;
			// padding-bottom: 1rem;
			border-radius: $border-radius;
			border-top-left-radius: unset;
			border-top-right-radius: unset;
			margin-top: 3rem;
			// width: 50%;
			border: 1px solid #e1e1e1;
			overflow: hidden;
		}

		.table_container {
			height: 382px;
			padding-top: 0rem;
			// padding-top: 0rem;
			padding-bottom: 3rem;
			position: relative;

			.first_row_static_container {
				// border: 1px solid red;
				background-color: white;
				z-index: 2;
				position: absolute;
				top: 3%;
				width: 130px;
				overflow: hidden;

				.icon_container {
					img {
						height: 25px;
						width: 25px;
						cursor: pointer;
					}
				}

				.line_between_row_col {
					width: 130px;
					background: #e0e0e0;
					height: 1px;
					position: absolute;
					top: 16.6%;
				}

				// border-radius: 16px;
				.first_row_static {
					// border: 2px solid red;
					height: 48px;
					text-align: center;
					width: 130px;
					padding-left: 10px;
					padding-right: 10px;
					display: flex;
					justify-content: center;
					align-items: center;
				}

				.first_row_static:first-child {
					.MuiTypography-body1 {
						margin-top: -12px;
						font: normal normal bold 15px Lato;
					}
				}

				.space_from_bottom {
					height: 40px;
				}
			}

			.Datatable {
				div:first-child {
					overflow-y: hidden;
				}
			}
		}

		.no_margin_top {
			margin-top: 1rem;
		}

		.table_title {
			width: 96%;
			margin: auto;
			margin-top: 3rem;
			margin-bottom: -2rem;
		}

		.tab_container {
			width: 100%;
			border-radius: $border-radius;
			overflow-x: hidden;
			position: relative;

			&__GradeAstar {
				height: 1.5rem;
				width: 1.5rem;
				position: absolute;
				top: 9.5rem;
				left: 8rem;
				z-index: 3;

				@media (min-width: 1919px) {
					height: 1.5rem;
					width: 1.5rem;
					position: absolute;
					top: 10.3rem;
					left: 9.5rem;
					z-index: 3;
				}

				@media (min-width: 1400px) and (max-width: 1919px) {
					height: 1.5rem;
					width: 1.5rem;
					position: absolute;
					top: 10.3rem;
					left: 10rem;
					z-index: 3;
				}
			}

			&__GradeBstar {
				height: 1.5rem;
				width: 1.5rem;
				position: absolute;
				top: 14.3rem;
				left: 8rem;
				z-index: 3;

				@media (min-width: 1919px) {
					height: 1.5rem;
					width: 1.5rem;
					position: absolute;
					top: 15.5rem;
					left: 9.5rem;
					z-index: 3;
				}

				@media (min-width: 1400px) and (max-width: 1919px) {
					height: 1.5rem;
					width: 1.5rem;
					position: absolute;
					top: 15.5rem;
					left: 10rem;
					z-index: 3;
				}
			}

			&__GradeCstar {
				height: 1.5rem;
				width: 1.5rem;
				position: absolute;
				top: 19rem;
				left: 8rem;
				z-index: 3;

				@media (min-width: 1919px) {
					height: 1.5rem;
					width: 1.5rem;
					position: absolute;
					top: 20.8rem;
					left: 9.5rem;
					z-index: 3;
				}

				@media (min-width: 1400px) and (max-width: 1919px) {
					height: 1.5rem;
					width: 1.5rem;
					position: absolute;
					top: 20.8rem;
					left: 10rem;
					z-index: 3;
				}
			}

			.report_toggle_container {
				// position: absolute;
				width: 100px;
				display: flex;
				// right: 5%;
				// top: 8%;
				justify-content: center;
				align-items: center;

				.tab_main_title {
					font-size: 1rem;
					font-weight: 500;
				}

				@media (min-width: 1920px) {
					top: -33%;
				}

				.Switch {
				}
			}

			.report_multiselect_container {
				position: absolute;
				// width: 100px;
				display: flex;
				right: 3%;
				top: -11%;
				justify-content: center;
				align-items: center;

				.tab_main_title {
					font-size: 1rem;
					font-weight: 500;
				}

				.Switch {
				}
			}

			.tab_title_container {
				width: 95%;
				// margin: auto;
				padding: 0.5rem rem;
				// margin-bottom: 1rem;
				margin-bottom: 0.5rem;
				// padding-bottom: 1rem;
				// margin-left: 3rem;
				margin: 2rem 2rem 1rem 2rem;

				@media (min-width: 1920px) {
					margin: 2rem 2rem 1rem 3rem;
				}
			}

			.remove-bottom-margin {
				margin-bottom: 1rem;
			}

			.predictiveActualBoxHeading {
				display: flex;
				align-items: center;
				justify-content: space-between;

				.tabsHeading {
					.MuiTabs-root {
						border: 1px solid rgb(180, 180, 180);
						border-radius: 35px;
						// font-size: 14px;
						height: 3rem;

						button {
							// margin: 0.5rem;
							// padding: 12px 32px;
							border-radius: 25px;
							font-size: 11px;
							// color: black
						}

						.Mui-selected {
							background-color: #9149ff;
							color: white;
						}
					}

					.MuiTabs-root {
						.MuiTabs-indicator {
							display: none;
						}
					}
				}
			}

			.conversion-estimates-container {
				@include lead_estimate_Default_parent_style;
				box-shadow: $global_box_shadow;
				position: relative;
				display: flex;
				flex-wrap: nowrap;
				flex-direction: row;
				gap: 10px;

				.circular_labels_container {
					display: flex;
					flex-direction: column;
					justify-content: center;
					gap: 20px;

					.transform_it {
						&:hover {
							transform: scale(1.15);
						}
					}
				}

				.circular_graph_container {
					// @include drawLine;
					position: relative;
					display: flex;
					align-items: center;
					flex-direction: column;

					.lead_estimate_circle {
						height: 240px;
						width: 240px;
						border-radius: 50%;
						// box-shadow: $global_box_shadow;
						box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
							rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
							rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;

						.percentage_val {
							font-size: 3rem;
							font-weight: 600;
							color: inherit;
						}

						.stats_description {
							width: 85%;
							text-align: center;
							color: inherit;
						}
					}
				}

				.stats_boxes_and_graph_container {
					// @include drawLine;
					display: flex;
					flex-direction: column;
					justify-content: space-evenly;

					.stats_container {
						display: flex;
						justify-content: space-evenly;

						.stats_boxes {
							max-width: 8rem;
							box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
								rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
							border-radius: $border-radius;
							padding: 2rem 0rem;
							transition: all 0.2s ease-in-out;
							@include center_via_flex;
							position: relative;
							width: 200px;
							max-width: 210px;

							.stats {
								@include center_via_flex;
								flex-direction: column;
								position: relative;

								.percentage_stats {
									text-align: center;
									font-size: 1.7rem;
									font-weight: 600;
									color: white !important;
								}

								.stats_name {
									text-align: center;
									width: 150px;
									font-size: 0.9rem;
									text-align: center;
									color: white;

									.__descriptions {
										position: absolute;
										top: -20%;
										right: -24%;
									}
								}
							}

							.stats_2_container {
								width: 100%;
								// height: 1px;
								height: 100%;

								// background-color: lightgrey;
								.single_stat {
									display: flex;
									flex-direction: column;
									justify-content: space-around;
									height: 100%;
									align-items: center;

									.single_sub_stat {
										display: flex;
										justify-content: space-between;
										height: 50%;
										width: 95%;
										margin: auto;

										.stats_desc {
											font-weight: 600;
										}
									}

									.horizontal_line {
										width: 100%;
										height: 1px;
										background-color: #ececec;
									}
								}
							}

							&:hover {
								box-shadow: $global_box_shadow;
							}
						}

						// .stats_boxes:not(:first-child) {
						// 	// border: 2px solid red;
						// 	padding: unset;
						// 	width: 200px;
						// 	max-width: 210px;
						// }
						.box_color_null {
							// border: 2px solid transparent;
							// margin: 0.2rem;
							.stat_color_null {
								// color: grey !important;
							}
						}

						.box_color_dark_null {
							border: 2px solid transparent;
							background-color: black;

							.stat_color_null {
								color: black !important;
							}
						}

						.box_color_0 {
							border: 2px solid $success_1;
							background-color: #f4f0fc;

							.stat_color_0 {
								color: $success_1 !important;
							}
						}

						.box_color_dark_0 {
							border: 2px solid $success_1;
							background-color: #c8b5f4;

							.stat_color_0 {
								color: black !important;
							}
						}

						.box_color_1 {
							border: 2px solid $success_2;
							background-color: #fccdff;

							.stat_color_1 {
								color: $success_2 !important;
							}
						}

						.box_color_dark_1 {
							border: 2px solid $success_2;
							background-color: #f99cff;

							.stat_color_1 {
								color: black !important;
							}
						}

						.box_color_2 {
							border: 2px solid $warning;
							background-color: #ffebfa;

							.stat_color_2 {
								color: $warning !important;
							}
						}

						.box_color_dark_2 {
							border: 2px solid $warning;
							background-color: #ff99e3;

							.stat_color_2 {
								color: black !important;
							}
						}

						.box_color_3 {
							border: 2px solid $danger;
							background-color: $danger;

							.stat_color_3 {
								color: $danger !important;
							}
						}
					}

					.graph_container {
						padding: 1rem;
						padding-top: 2rem;
						padding-bottom: 0rem;
						border-radius: $border-radius;
						max-height: 280px;
						overflow: hidden;
						margin-top: 10rem;
						box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
							rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
						transition: all 0.2s ease-in-out;

						&:hover {
							box-shadow: $global_box_shadow;
						}
					}
				}
			}

			.circular_graph_container {
				// border: 2px solid red;
				&__insideBox {
					width: 36rem;
					height: 18rem;
					background-color: white;
					border-radius: 20px;
					padding: 1.3rem;
					border: 1px solid #0000001a;
				}
			}

			.channel_estimates_container_bottomGraph {
				@include lead_estimate_Default_parent_style;
				box-shadow: $global_box_shadow;
				display: flex;
				justify-content: start;
				// padding-bottom: 5rem;
				padding: 0rem !important;

				// position: relative;
				// background-color: #ffffff;
				.CE_Graph {
					width: 100%;
					margin-top: 2rem;

					.highcharts-container {
						border-radius: 20px;
					}
				}

				.CE_Graph_dark {
					width: 100%;
					margin-top: 2rem;

					.highcharts-container {
						border-radius: 20px;
					}

					.highcharts-background {
						fill: #060f1d;
					}
				}
			}

			.channel_estimates_container {
				@include lead_estimate_Default_parent_style;
				box-shadow: $global_box_shadow;
				display: flex;
				justify-content: start;
				// padding-bottom: 5rem;
				padding: 0rem !important;
				position: relative;

				// @include drawLine();
				.report_toggle_container {
					// position: absolute;
					// right: 2%;
					// top: -27%;
					width: 170px;
					display: flex;
					flex-direction: row-reverse;

					.tab_main_title {
						font-size: 1rem;
						font-weight: 500;
					}
				}

				.report_toggle_containerwithGraph {
					// position: absolute;
					// right: 5%;
					// top: -8%;
					// width: 170px;
					font-weight: 400 !important;
					font-size: large !important;
					display: flex;
					flex-direction: row-reverse;

					.tab_main_title {
						font-size: 1rem;
						font-weight: 500;
					}
				}

				.dropdown__container {
					width: 200px;
					position: absolute;
					right: 39%;
					top: 4%;
				}

				.pagination_container {
					position: absolute;
					/* Important */
					bottom: -15%;
					/* Position Y halfway in */
					right: -8%;
					/* Position X halfway in */
					transform: translate(-20%, -50%);

					/* Move it halfway back(x,y) */
					.MuiPagination-ul {
						.Mui-selected {
							background-color: #9149ff;
						}
					}
				}

				.CE_table_dark {
					height: 100%;
					width: 100%;

					.highcharts-container {
						border-radius: 20px;
					}

					.bottom_table_total_col_container {
						display: flex;
						justify-content: space-around;
						border-bottom-left-radius: 16px;
						border-bottom-right-radius: 16px;
						overflow: hidden;

						.bottom_table_total_col {
							padding: 0.5rem 0rem;
							flex: 1 1;
							text-align: center;
							// border: 1px solid #d4d4d4;
							border-bottom: none;

							.tab_main_title {
								font-size: 0.9rem;
								font-weight: 600;
							}
						}
					}

					.rdt_TableRow {
						padding: 0px !important;

						.rdt_TableCell {
							padding-left: 0px;
							padding-right: 0px;
						}
					}

					.rdt_TableCell {
						div:nth-child(-n + 2) {
							width: 100%;
							height: 100%;
							display: flex;
							justify-content: center;
							align-items: center;
						}

						.channel_name {
							justify-content: flex-start !important;
							padding: 0px 10px !important;
							text-overflow: ellipsis;
							// min-width: 100px;
							white-space: nowrap;
							width: auto;
							overflow: hidden;
							// border: 2px solid red;
							display: block !important;
							height: unset !important;
						}

						// .typo {
						// 	white-space: nowrap;
						// 	overflow: hidden;
						// 	text-overflow: ellipsis;
						// }
					}
				}

				.CE_table {
					height: 100%;
					width: 100%;

					.highcharts-container {
						border-radius: 20px;
					}

					.bottom_table_total_col_container {
						display: flex;
						justify-content: space-around;
						border-bottom-left-radius: 16px;
						border-bottom-right-radius: 16px;
						overflow: hidden;

						.bottom_table_total_col {
							padding: 0.5rem 0rem;
							flex: 1 1;
							text-align: center;
							border: 2px solid #d4d4d4;
							border-bottom: none;

							.tab_main_title {
								font-size: 0.9rem;
								font-weight: 600;
							}
						}

						.bottom_table_total_col:first-child {
							border-left: none;
						}

						.bottom_table_total_col:last-child {
							border-right: none;
						}
					}

					.rdt_TableRow {
						padding: 0px !important;

						.rdt_TableCell {
							padding-left: 0px;
							padding-right: 0px;
						}
					}

					.rdt_TableCell {
						div:nth-child(-n + 2) {
							width: 100%;
							height: 100%;
							display: flex;
							justify-content: center;
							align-items: center;
						}

						.channel_name {
							justify-content: flex-start !important;
							padding: 0px 10px !important;
							text-overflow: ellipsis;
							// min-width: 100px;
							white-space: nowrap;
							width: auto;
							overflow: hidden;
							// border: 2px solid red;
							display: block !important;
							height: unset !important;
						}
					}
				}

				.CE_stats_container {
					// display: flex;
					// flex-direction: column;
					// justify-content: start;
					justify-content: center;
					align-items: center;
					margin-bottom: 2rem;
					max-width: 84%;

					// flex: 1;
					.CE_stats {
						@include center_via_flex();
						flex-direction: column;
						text-align: center;
						padding: 1.5rem;
						// justify-content: space-between;
						box-shadow: $global_box_shadow;
						border-radius: $border-radius;
						// width: 72%;
						height: auto;
						// margin-top: 2rem;
						background-color: #dbcdf0;
						transition: height 0.2s ease-in-out;

						.tab_main_title {
							font-size: 2rem;
							font-weight: 600;
							color: #9149ff;
						}

						.tab_title_1 {
							color: rgb(2, 188, 119);
						}

						.tab_main_body {
							color: #2a2a2a;
							// color: white;
						}
					}
				}
			}

			.reduce-height {
				height: unset;
			}

			.dropdown_section {
				width: 96%;
				// margin: auto;
				display: flex;
				justify-content: end;
				align-items: center;

				.dropdown_and_title_container {
					width: 24%;

					// margin-right: 2rem;
					.MuiTypography-h6 {
						// margin-bottom: 1rem;
					}

					.dropdown {
						margin-top: 0.3rem;
					}
				}

				.advance_option_btn_container {
					width: 260px;
				}
			}

			.main_report_container_mmm {
				width: 98%;
				height: auto;
				margin: auto;
				margin-top: 5rem;
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: 3rem;

				.first_row,
				.second_row {
					flex: 0.7;
					border-radius: 6px !important;
					overflow: hidden;
					display: flex;
					flex-direction: column;
					gap: 0rem;

					.ai_graph_title {
						margin-bottom: 1rem;
					}

					.graph_and_table_container {
						width: auto;
						border-radius: 6px !important;
						margin-top: 0px !important;
						padding-top: 1rem !important;
					}

					.ai_first_container {
						margin-bottom: 2rem;
					}
				}
			}
		}

		.Advertisement_channel_effect_tabs {
			width: 100%;
			// margin-left: 1%;
			display: flex;
			// margin-top: 2rem;
			position: absolute;
			top: 5.4rem;
			// z-index: 3;
			// background: #f4f7fc;
			padding: 0.5rem 1rem;
			// border-top-left-radius: 16px;
			z-index: 9;

			.div_tab0 {
				@include tabStyle(border-radius, $border-radius, false, false);
				// margin-right: 1rem;
				// span {
				// 	font-size: 0.9rem;
				// }
				border-radius: 30px !important;

				span {
					font-size: 0.9rem;
				}

				// :hover {
				// 	background-color: var(--primary-theme);
				// 	color: white;
				// 	transition: 0.3s ease-in-out;
				// }
			}

			.div_tab1 {
				@include tabStyle(border-left, 1px solid #80808040, false, false);
				display: none;
				// border-radius: 30px !important;
			}

			.div_tab2 {
				@include tabStyle(
					border-radius,
					$border-radius,
					border-left,
					1px solid #80808040
				);

				span {
					font-size: 0.9rem;
				}

				border-radius: 30px !important;
				// :hover {
				// 	background-color: var(--primary-theme) !important;
				// 	color: white;
				// 	transition: 0.3s ease-in-out;
				// }
			}
		}

		.optimize_budget_container {
			.title {
				margin-left: 2%;
			}

			.threshold_and_dropdown_container {
				display: flex;
				justify-content: space-between;
				padding-right: 4rem;

				.dropdown_section {
					width: 67%;
					margin-top: 2rem;
					margin-left: 0%;

					.dropdown_and_title_container {
						width: 18.5%;
					}

					.switch_container {
						display: flex;
						align-items: center;
					}
				}

				.budget_threshold_container {
					width: 350px;
					height: 150px;
					display: flex;
					flex-direction: column;
					justify-content: space-between;

					.threshold_container_title {
						text-align: center;
					}

					.threshold {
						display: flex;
						width: 100%;
						align-items: center;
						// border: 2px solid red;
						justify-content: space-between;
					}
				}
			}
		}
	}

	.estimate-conversion-tab {
		// border: 2px solid red;
		// position: absolute;
		margin-left: 2.5%;
		max-height: 100px;
		min-width: 200px;
		left: 2.5%;
		top: 4.65%;
		display: flex;
		justify-content: space-around;
		background-color: rgba(183, 183, 183, 0.644);
		border-top-left-radius: 16px;
		border-top-right-radius: 16px;
		overflow: hidden;
		transition: all 0.2s ease-in-out;

		// 1450
		@media (max-width: 1451px) {
			top: 4.3%;
		}

		@media (max-width: 1350px) {
			top: 4%;
		}

		.tab-1 {
			border-right: 1px solid rgb(186, 186, 186);
			// background-color: transparent;
		}

		.tab-1,
		.tab-2 {
			padding: 0.8rem;
			cursor: pointer;
			text-align: center;
			flex: 1;
			transition: all 0.2s ease-in-out;
			// border: 2px solid red;
		}
	}
}

.Advertisement_channel_effect .width_95_margin_0_auto {
	width: 95% !important;
	margin: 0rem auto !important;
}

.expenditure__table-view {
	.fixed_top_header_title {
		padding: 0.5rem;
		border: 2px solid red;
	}
}

.tab_container_table {
	position: relative;

	.tab_container_table_child {
		width: 190px;
		// border: 2px solid red;
		display: flex;
		justify-content: space-around;
		position: absolute;
		top: 4.4%;
		z-index: 2;
		left: 1.7%;
		border-top-left-radius: 16px;
		border-top-right-radius: 16px;
		overflow: hidden;

		.tab_0,
		.tab_1 {
			width: 50%;
			padding: 0.7rem 1rem;
			cursor: pointer;
			background-color: rgba(183, 183, 183, 0.644);
			transition: all 0.2s ease-in-out;
		}

		.tab_0 {
			border-right: 1px solid #80808045;
		}
	}
}
.highcharts-axis-labels text {
	font-weight: 600 !important;
}
.Advertisement_channel_effect {
	.table_bottom_total {
		border-top: 1px solid rgba(0, 0, 0, 0.12);
	}

	.dashboard__table-view {
		margin-bottom: unset !important;
	}

	.table_total {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		// padding-left: unset !important;
		// padding-right: unset !important;
	}

	.rdt_TableCol_Sortable > div {
		margin-right: 0px !important;
		border: none !important;
		border-radius: 0px !important;
		overflow: hidden !important;
		white-space: nowrap !important;
		text-overflow: ellipsis !important;
	}

	.rdt_TableCol_Sortable {
		margin-right: 0px !important;
	}

	.table-col-total {
		cursor: default;
	}

	.global_setting_toast {
		background: rgb(235, 200, 196);
		position: fixed;
		z-index: 1000;
		padding: 0.25rem 1rem;
		width: 100%;
		height: 64px;
		margin-top: 8px;
		margin-left: 0px;
	}
}

.actualHeading {
	top: 11rem;
	position: absolute;
	font-size: 1rem;
}

.circularGraphsHeadings {
	position: relative;
	top: 2.5rem;
	font-size: 18px;
	font-weight: 700;
}

.reportHeading {
	position: relative;
	margin: 1.8rem 0rem 1rem 0rem;
}

.flexCenter{
	display:flex;
	align-items: center;
}

.graphDropdown {
	position: relative;
	top: -2rem;
	width: 8rem;
	left: 50rem;
}

.leadScoringDrilldown {
	width: 100%;
	height: 100%;

	&__drilldownContainer {
		margin: 3rem;
		padding: 1rem;
		height: 90%;
		width: 90%;
		background-color: #289d8f;
	}
}

.AdPlatform {
	padding: 1rem 0rem;

	&__platform {
		text-align: center;
		min-width: 552px;
		// min-width: 900px;
		min-height: 256px;
		// min-height: 400px;

		&__title {
			font-weight: 600 !important;
			font-size: 26px !important;
			line-height: 2 !important;
			text-align: center;
			margin-top: 0.5rem;
		}

		&__item {
			margin: 1.1rem 1rem;
			display: inline-flex;
			width: 132px;
			height: 120px;
			align-items: center;
			justify-content: center;
			position: relative;
			border: 2px solid transparent;
			border-radius: 30px;
			box-shadow: 0px 10px 24px #1715206e;

			&__checkbox {
				position: absolute;
				top: 0px;
				right: 0px;
			}

			&__selected {
				border-radius: 30px;
				// box-shadow: 0px 10px 24px #6f54f645;
			}
		}

		&__label {
			text-transform: capitalize;
		}

		&__img {
			width: 3.4rem;
			height: 3.4rem;
		}

		&__button {
			margin: 1rem 0rem;
		}
	}

	&__addSearchConsole {
		text-align: center;
		min-height: 130px !important;
		margin-bottom: 3rem;
	}

	&__item {
		margin: 1rem 1rem;
		display: inline-flex;
		width: 132px;
		height: 120px;
		align-items: center;
		justify-content: center;
		position: relative;
		box-shadow: 0px 10px 24px #1715206e;

		&__checkbox {
			position: absolute;
			top: 0px;
			right: 0px;
		}

		&__selected {
			border: 2px solid black;
			border-radius: 30px;
			box-shadow: 0px 10px 24px #6f54f645;
		}

		&__box {
			border-radius: 30px;
			box-shadow: 0px 10px 24px #6f54f645;
		}
	}

	&__configure-googleads-outerDiv {
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: center;
	}

	&__configure-googleads {
		width: 60%;
		margin: 40px 0;
		height: 94%;
		// background-color: white;
		border-radius: 20px;
		padding: 1rem;
		// overflow-y: auto;
	}

	&__configure {
		width: 60%;
		height: 84%;
		// background-color: white;
		border-radius: 20px;
		padding: 1rem;
		overflow-y: auto;

		.multi-select {
			min-width: 100%;
		}

		.multi-select-dark {
			min-width: 100%;
		}

		&__configure {
			width: 60%;
			height: 84%;
			// background-color: white;
			border-radius: 20px;
			padding: 1rem;
			overflow-y: auto;

			&__row {
				margin: auto;
				width: 68%;
				padding: 0.8rem;
			}

			&__dropdown {
				margin: auto;
				width: 70%;
				padding: 0.4rem;
			}
		}

		&__row {
			margin: auto;
			width: 68%;
			padding: 0.8rem;
		}

		&__dropdown {
			margin: 3rem auto;
			width: 70%;
			padding: 0.4rem;
		}
	}

	&__gscConfig {
		width: 60%;
		height: 80%;
		background-color: white;
		border-radius: 20px;
		padding: 1rem;
		overflow-y: auto;

		.multi-select {
			min-width: 100%;

			.dropdown-container {
				z-index: none;
			}
		}

		&__disabledState {
			opacity: 0.5;
		}

		&__row {
			margin: auto;
			width: 68%;
			padding: 0.8rem;
		}

		&__dropdown {
			margin: auto;
			width: 70%;
			padding: 0.4rem;
		}
	}

	&__icons {
		width: 32px;
		height: 32px;
	}
}
.confusionMatrix_tab {
	border-radius: 20px;
	padding: 40px 40px;
	display: flex;
	flex-direction: column;
	justify-content: center;

	.confusionMatrix_tab_text {
		width: 100%;
		display: flex;
		text-align: center;
		font-size: 11px;
		flex-direction: column;
		background-color: transparent;
		justify-content: center;
		align-content: center;
		flex-wrap: wrap;
	}
}

.Multiselect {
	width: 350px;
	.rmsc .dropdown-content {
position: relative !important;
	}
}
