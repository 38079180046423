.bargraph {
	overflow: hidden;
	// background: var(--page-bg-color) 0% 0% no-repeat padding-box;
	box-shadow: 0px 25px 30px #0000001a;
	border-radius: 20px;
	padding: 1rem;
	table {
		font-family: 'Lato' !important;
	}
	tr > td {
		font-size: 12px !important;
	}
	thead > tr {
		font-size: 14px !important;
	}
	tbody > tr > th {
		font-size: 12px !important;
	}
	// table > tbody {
	// 	:nth-child(even){
	// 		background-color: red;
	// 	}

	// }
}

.highcharts-tooltip-container {
	z-index: 9999 !important;
}
